import { hidePersonalCampaignPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

// Components
import Popup, { PopupButtons } from "@modules/popup/Popup"
import { PersonalCampaign } from "../PersonalCampaign"
import Button from "@ng-mw/reol/components/Button"

const PersonalCampaignPopup = () => {
    const dispatch = useDispatch<any>()
    const { visible, campaign } = useSelector((state: RootState) => state.app.personalCampaignPopup)

    const handleClose = () => {
        dispatch(hidePersonalCampaignPopup())
    }

    return (
        <Popup isOpen={visible} onClose={handleClose} isHeaderActionsOverflow>
            <PersonalCampaign campaign={campaign} />
            <PopupButtons isSimple>
                <Button onClick={handleClose} text="Tilbake" isSecondary />
            </PopupButtons>
        </Popup>
    )
}

export default PersonalCampaignPopup
