import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "@app/types"
import isWithinInterval from "date-fns/isWithinInterval"
import parseISO from "date-fns/parseISO"

import trumfTIcon from "@ng-mw/reol/icons/trumfT"
import vippsIcon from "@resources/icons/vipps-smiley-icon"

import Button from "@ng-mw/reol/components/Button"
import Tips from "@modules/shared/Tips"
import Container from "@modules/shared/Container"
import PersonalCampaigns from "@modules/user/PersonalCampaigns"
import { showTopBannerPopup } from "@app/store/app/appActions"

const TopBanner = () => {
    const dispatch = useDispatch<any>()
    const { bannerInfo } = useSelector((state: RootState) => state.settings)
    const { isOrganization } = useSelector((state: RootState) => state.user.data.household)

    if (!bannerInfo?.bannerType) return null

    const now = new Date(new Date().toDateString())

    const handleShowPopup = () => {
        dispatch(showTopBannerPopup())
    }

    const isDateWithinDates = isWithinInterval(now, {
        start: parseISO(bannerInfo.topBannerFrom),
        end: parseISO(bannerInfo.topBannerTo),
    })

    const getBannerIcon = (bannerType: string) => {
        switch (bannerType) {
            case "trumf":
                return trumfTIcon
            case "vipps-icon":
                return vippsIcon
            default:
                return null
        }
    }

    const icon = getBannerIcon(bannerInfo.bannerType)

    const text = bannerInfo.topBannerText
        ? bannerInfo.topBannerText
        : "Husk at på torsdag så er det 3% bonus på hele butikken!"

    if (isDateWithinDates && bannerInfo.enableTopBanner && !isOrganization) {
        return (
            <Container>
                <Tips
                    size={{ height: "36px", width: "36px" }}
                    illustration={icon}
                    text={!bannerInfo.showAppPopUp ? text : undefined}
                    illustrationLeftOffset="0"
                >
                    {bannerInfo.showAppPopUp ? (
                        <div
                            className="app-tip__content"
                            style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <p className="app-tip__text">{text}</p>
                            <Button
                                onClick={handleShowPopup}
                                text="Les mer"
                                isPlainLink
                                className="app-tip__button"
                                style={{ whiteSpace: "nowrap" }}
                            />
                        </div>
                    ) : null}
                </Tips>
                <PersonalCampaigns />
            </Container>
        )
    } else {
        return (
            <Container>
                <PersonalCampaigns />
            </Container>
        )
    }
}

export default TopBanner
