import "./style/personal-campaign.scss"

import React from "react"

import Icon from "@ng-mw/reol/components/Icon"
import { SapCrmCampaign, getPersonalCampaignPropsType } from "@ng-mw/shared-react-components/user"
import { dateHelper } from "@ng-mw/shared-react-components"

const { formatDate, NgcDateFormat } = dateHelper

interface PersonalCampaignProps {
    campaign?: SapCrmCampaign
}

const PersonalCampaignHeader = ({ type }: { type: string }) => {
    const campaginTypeData = getPersonalCampaignPropsType(type)

    return (
        <div
            className={`personal-campaign__top personal-campaign__background personal-campaign__background--${campaginTypeData?.background}`}
        >
            {campaginTypeData?.backgroundIcon ? (
                <Icon
                    className="personal-campaign__background-icon"
                    icon={campaginTypeData.backgroundIcon}
                    size={{ width: "343px", height: "171px" }}
                />
            ) : null}
            <div className="personal-campaign__icon-wrapper">
                <Icon
                    className="personal-campaign__icon"
                    icon={campaginTypeData?.icon}
                    size={{ width: "81px", height: "81px" }}
                />
            </div>
        </div>
    )
}
export const PersonalCampaign = ({ campaign }: PersonalCampaignProps) => {
    if (!campaign) return null

    return (
        <div className="personal-campaign">
            <PersonalCampaignHeader type={campaign.type} />
            <div className="personal-campaign__content">
                <h3 className="personal-campaign__title">{campaign.beskrivelse}</h3>
                <p>{campaign.tilleggsTekst}</p>
                <p>Gyldig t.o.m: {formatDate(campaign?.bonusGyldigTil, NgcDateFormat)}</p>
            </div>
        </div>
    )
}
