import "./style/campaigns-list.scss"

import React, { CSSProperties, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { all } from "./store/actions/campaigns"
import * as tracking from "@app/tracking"
import { CampaignType, CampaignTypeEnum, RootState } from "@app/types"

// Components
import HorizontalScroller from "@modules/shared/HorizontalScroller"
import LoadingSkeleton from "@modules/shared/LoadingSkeleton"
import CampaignItem from "./CampaignItem"
import Container, { Content } from "@modules/shared/Container"

interface CampaignsList {
    type?: CampaignTypeEnum
    children?: React.ReactNode
    title?: string
    linkText?: string
    to?: string
    style?: CSSProperties
}

const CampaignsList = ({
    type = CampaignType.All,
    children,
    title,
    linkText,
    to,
    style,
}: CampaignsList) => {
    const dispatch = useDispatch<any>()
    const { data, loading } = useSelector((state: RootState) => state.offerCampaigns)
    const filteredCampaigns = data[type]

    useEffect(() => {
        dispatch(all(type))
    }, [dispatch, type])

    useEffect(() => {
        if (filteredCampaigns?.length > 0) {
            tracking.offerCampaignsView(filteredCampaigns)
        }
    }, [filteredCampaigns])

    const innerContent = (
        <div className="campaigns-list">
            {children}
            {loading && !filteredCampaigns?.length ? (
                <LoadingSkeleton isLoading>
                    <div className="campaigns-list__skeleton">
                    </div>
                </LoadingSkeleton>
            ) : null}
            {filteredCampaigns?.length > 0 ? (
                <HorizontalScroller
                    singleItemFillter
                    small={true}
                >
                    {filteredCampaigns.map((item, index) => (
                        <CampaignItem
                            key={item.campaignId + index}
                            item={item}
                            index={index}
                        />
                    ))}
                </HorizontalScroller>
            ) : null}
        </div>
    )

    return title ? (
        <Container
            title={title}
            linkText={linkText}
            to={to}
            content={Content.FullWidth}
            style={style}
        >
            {innerContent}
        </Container>
    ) : (
        <div
            title={title}
            content={Content.FullWidth}
            style={style}
        >
            {innerContent}
        </div>
    )
}

export default CampaignsList
