import "./style/app-status.scss"

import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "@app/types"

import Alert from "@ng-mw/reol/components/Alert"
import { animationDuration } from "@app/LayoutSettings"
import { CSSTransition } from "react-transition-group"

// How many ms to wait until showing network error message
// (Should not be 0 because phones are regularly offline for a few ms
// whenever they switch network provider)
const MS_UNTIL_WARNING = 1000

// How many ms the network is back message should be visible, when reload is not required
const MS_UNTIL_AUTOHIDE = 2000

// How many ms of no network until requiring reload
const MS_UNTIL_RELOAD_REQUIRED = 10 * 60 * 1000 // 10 * 60 * 1000 = 10 min

const AppNetworkStatus = () => {
    const isOnline = useSelector((state: RootState) => state.native.online)
    const [isOpen, setIsOpen] = useState(false)
    const [isReload, setIsReload] = useState(false)

    const lostNetworkAt = useRef<number | undefined>(undefined)
    const triggerDelayTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)

    useEffect(() => {
        if (!isOnline) {
            lostNetworkAt.current = Date.now()
            clearTimeout(triggerDelayTimeout.current)
            triggerDelayTimeout.current = setTimeout(() => setIsOpen(true), MS_UNTIL_WARNING)
        } else {
            clearTimeout(triggerDelayTimeout.current)
            const now = Date.now()
            if (lostNetworkAt.current && (now - lostNetworkAt.current > MS_UNTIL_RELOAD_REQUIRED) ) {
                setIsReload(true)
            } else {
                // autohide after a little delay.
                triggerDelayTimeout.current = setTimeout(() => setIsOpen(false), MS_UNTIL_AUTOHIDE)
            }
            lostNetworkAt.current = undefined
        }
    }, [isOnline])

    const handleReloadApp = () => {
        window.location.reload()
    }

    return (
        <CSSTransition
            in={isOpen}
            unmountOnExit
            classNames="slide-up"
            timeout={{ exit: animationDuration, enter: animationDuration }}
            enter
            exit
        >
            <Alert
                show
                type={isOnline ? "success" : "warning"}
                className="app-status"
                retryText={isReload ? "Restart appen" : undefined}
                onRetryClick={isReload ? handleReloadApp : undefined}
                message={isOnline ? "Nettverkstilkobling er tilbake!" : "Mangler nettverkstilkobling!"}
            />
        </CSSTransition>
    )
}

export default AppNetworkStatus
