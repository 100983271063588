import { DebugLogger, NativeEventBridge, LifecycleEmitter } from "@ng-mw/shared-react-components"
import type { MemoryHistory } from "history"
import MenyNativeEvent from "@app/events/MenyNativeEvent"
import MenyLifecycleEvent from "@app/events/MenyLifecycleEvent"
import PathNames from "@app/const/PathNames"
import * as tracking from "@app/tracking"
import { hideHandoverPickerPopup } from "@app/store/app/appActions"
import { showRecipePopup } from "@app/store/app/appCreator"
import { IStoredShoppingList } from "@ng-mw/platform-interfaces"
import { mapNativeLinkToRoute } from "@app/helpers"

// This is just type-nonsense to convince typescript that dataLayer is a valid attribute of window
// (pretty stupid, but that's typescript...)
declare global {
    interface Window {
        dataLayer: unknown[]
    }
}

export const navigateToOptions = {
    TILBUD: "TILBUD",
    KNALL: "KNALL",
    UKEMENY: "UKEMENY",
    BUTIKK: "BUTIKK",
    KHI: "KHI",
    KLIMA: "KLIMA",
    OPPDAG: "OPPDAG",
    CARDS: "CARDS",
    KURV: "KURV",
    NETTHANDELHISTORIE: "NETTHANDELHISTORIE",
    ALLETRANSAKSJONER: "ALLETRANSAKSJONER",
    HJEM: "HJEM",
    MENYMER: "MENYMER",
    KUPONGER: "KUPONGER",
    BESPARELSER: "BESPARELSER",
    BONUS: "BONUS",
    BONUSTOBANK: "BONUSTOBANK",
    TVAKSJONEN: "TVAKSJONEN",
    MATSENTRALEN: "MATSENTRALEN",
    KIRKENS: "KIRKENS",
}

// Using `store: Store` just gives this error:
// "Argument of type '(dispatcher: any) => void' is not assignable to parameter of type 'AnyAction'."
const historyConnectors = (store: any, history: MemoryHistory): void => {
    LifecycleEmitter.on(MenyLifecycleEvent.GoToContactDetails, async () => {
        store.dispatch(hideHandoverPickerPopup())
        history.push(PathNames.ContactDetails)
    })
    interface NavigateToEventData {
        navigateTo: string
        source: "link" | "push"
    }

    NativeEventBridge.listen(MenyNativeEvent.NavigateTo, ({ navigateTo, source }: NavigateToEventData) => {
        DebugLogger.log(`Got push with navigateTo: ${navigateTo}. Source: ${source}`)

        // link - associated domains: https://developer.apple.com/documentation/xcode/supporting-associated-domains.
        // Push - native sends us a string with the name of where the user should be sent

        // If source = link: user has clicked on a link we want to open in the app. Else navigateTo comes from push

        if (source === "link") {
            const path = mapNativeLinkToRoute(navigateTo)
            tracking.nativeAppLink({ navigateTo, source })
            history.push(path)
        } else {
            switch (navigateTo) {
                case navigateToOptions.TILBUD:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.GenericOffers)
                    break
                case navigateToOptions.KNALL:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.BargainOffers)
                    break
                case navigateToOptions.UKEMENY:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.RecipesHome)
                    break
                case navigateToOptions.BUTIKK:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.Search)
                    break
                case navigateToOptions.KHI:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.DietHome)
                    break
                case navigateToOptions.KLIMA:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.ClimateHome)
                    break
                case navigateToOptions.OPPDAG:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.Home)
                    break
                case navigateToOptions.CARDS:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.TrumfBankCards)
                    break
                case navigateToOptions.KURV:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.Cart)
                    break
                case navigateToOptions.NETTHANDELHISTORIE:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.OrderHistory)
                    break
                case navigateToOptions.ALLETRANSAKSJONER:
                    tracking.pushNotification(navigateTo)
                    history.push({ pathname: PathNames.PurchaseHistory, state: { tabIndex: 1 } })
                    break
                case navigateToOptions.HJEM:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.Home)
                    break
                case navigateToOptions.MENYMER:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.LoyaltyClub)
                    break
                case navigateToOptions.KUPONGER:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.Vouchers)
                    break
                case navigateToOptions.BESPARELSER:
                    tracking.pushNotification(navigateTo)
                    history.push({ pathname: PathNames.Savings, state: { tabIndex: 0, forceRefresh: true } })
                    break
                case navigateToOptions.BONUS:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.UseBonus)
                    break
                case navigateToOptions.BONUSTOBANK:
                    tracking.pushNotification(navigateTo)
                    history.push(PathNames.UseBonusToBank)
                    break
                case navigateToOptions.TVAKSJONEN:
                    tracking.pushNotification(navigateTo)
                    history.push({ pathname: PathNames.UseBonusToCharity, state: { tabIndex: "TVAK" } })
                    break
                case navigateToOptions.MATSENTRALEN:
                    tracking.pushNotification(navigateTo)
                    history.push({ pathname: PathNames.UseBonusToCharity, state: { tabIndex: "MATS" } })
                    break
                case navigateToOptions.KIRKENS:
                    tracking.pushNotification(navigateTo)
                    history.push({ pathname: PathNames.UseBonusToCharity, state: { tabIndex: "KIRK" } })
                    break
                case "":
                    tracking.pushNotification("(do nothing)")
                    DebugLogger.log("navigateTo empty string, do nothing")
                    break
                default:
                    tracking.pushNotification(`Error unknown navigateTo target: ${navigateTo}`)
                    DebugLogger.warn("Got unknown navigateTo argument:", navigateTo)
            }
        }
    }, false) // false is very important; it makes it listen more than once
    DebugLogger.log("Load-order-debug: Listening for MenyNativeEvent.NavigateTo IMPORTANT")

    LifecycleEmitter.on(MenyLifecycleEvent.GoToSavingsClicked, (state: any) => {
        history.push({ pathname: PathNames.Savings, state })
    })

    LifecycleEmitter.on(MenyLifecycleEvent.GoToShoppinglistClicked, () => {
        history.push(PathNames.ShoppingListsHome)
    })

    LifecycleEmitter.on(MenyLifecycleEvent.GoToTransactionHistoryClicked, () => {
        history.push(PathNames.PurchaseHistory)
    })

    LifecycleEmitter.on(MenyLifecycleEvent.GoToMyRecipesClicked, () => {
        history.push({
            pathname: PathNames.RecipesHome,
            state: {
                initialTab: 1,
            },
        },
        )
    })

    LifecycleEmitter.on(MenyLifecycleEvent.GoToMyOrders, () => {
        history.push(PathNames.OrderHistory)
    })

    interface GoToOrderEventData {
        ngOrderId: string
        isArchived: boolean
        pickupCode: string
    }

    LifecycleEmitter.on(MenyLifecycleEvent.GoToOrder, ({ ngOrderId, isArchived, pickupCode }: GoToOrderEventData) => {
        history.push({
            pathname: `/user/orders/${ngOrderId}`,
            state: {
                ngOrderId,
                pickupCode,
                isArchived,
            },
        })
    })

    interface GoToRecipeEventData {
        recipeId: string
    }

    LifecycleEmitter.on(MenyLifecycleEvent.GoToRecipe, ({ recipeId }: GoToRecipeEventData) => {
        store.dispatch(showRecipePopup(recipeId))
    })

    LifecycleEmitter.on(MenyLifecycleEvent.GoToCouponsClicked, () => {
        history.push(PathNames.Vouchers)
    })

    interface GoToShoppingListDetailsClickedEventData {
        id: string
        shoppingList: IStoredShoppingList
    }

    LifecycleEmitter.on(MenyLifecycleEvent.GoToShoppingListDetailsClicked, ({ id, shoppingList }: GoToShoppingListDetailsClickedEventData) => {
        history.push({
            pathname: `/shopping-lists/${id}`,
            state: {
                id,
                shoppingList,
            },
        })
    })
}

export default historyConnectors
