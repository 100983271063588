import "./style/personal-campaigns.scss"

import { showPersonalCampaignPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"
import {
    PersonalCampaign,
    userHelpers,
    SapCrmCampaign,
    bonusCampaignActions,
} from "@ng-mw/shared-react-components/user"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Swiper, SwiperSlide } from "swiper/react"

import menyPercentWhite from "@ng-mw/reol/icons/menyPercentWhite"

// Components
import Container from "@modules/shared/Container"
import Icon from "@ng-mw/reol/components/Icon"

interface PersonalCampaignsProps {
    showTitle?: boolean
    showEmptyCard?: boolean
    campaigns?: SapCrmCampaign[]
}

const PersonalCampaigns = ({
    showTitle = false,
    showEmptyCard = false,
    campaigns,
}: PersonalCampaignsProps) => {
    const dispatch = useDispatch<any>()
    const { data } = useSelector((state: RootState) => state.bonusCampaign)
    const { isOrganization } = useSelector((state: RootState) => state.user.data.household)

    useEffect(() => {
        dispatch(bonusCampaignActions.getRelevantBonuses())
    }, [])

    const { filteredCampaigns } = useMemo(() => {
        let filteredCampaigns = []

        if (campaigns?.length) {
            filteredCampaigns = campaigns
        } else {
            if (Array.isArray(data) && data.length) {
                filteredCampaigns = userHelpers.getActivePersonalCampaigns(data)
            }
        }

        return {
            filteredCampaigns,
        }
    }, [data, campaigns])

    const personalCampaignTitle = `Dine personlige goder (${filteredCampaigns?.length})`

    if (isOrganization || (!filteredCampaigns?.length && !showEmptyCard)) return null

    if (!filteredCampaigns?.length && showEmptyCard)
        return (
            <Container title={personalCampaignTitle}>
                <div className="personal-campaign-empty">
                    <Icon
                        className="personal-campaign-empty__icon"
                        icon={menyPercentWhite}
                        size={{ width: "30px", height: "30px" }}
                    />
                    <p>Akkurat nå har du ingen ekstra bonuskampanje</p>
                </div>
            </Container>
        )

    const handleClick = (campaign: SapCrmCampaign) => {
        dispatch(showPersonalCampaignPopup({ campaign }))
    }

    if (filteredCampaigns?.length === 1)
        if (showTitle) {
            return (
                <Container
                    className="personal-campaigns"
                    title={showTitle ? personalCampaignTitle : ""}
                >
                    {filteredCampaigns.map((campaign: SapCrmCampaign) => (
                        <button onClick={() => handleClick(campaign)} key={campaign.kampanjeId}>
                            <PersonalCampaign campaign={campaign} />
                        </button>
                    ))}
                </Container>
            )
        } else {
            return filteredCampaigns?.map((campaign: SapCrmCampaign) => (
                <button onClick={() => handleClick(campaign)} key={campaign.kampanjeId}>
                    <PersonalCampaign campaign={campaign} />
                </button>
            ))
        }
    return (
        <Container
            className="personal-campaigns"
            content="full-width"
            title={showTitle ? personalCampaignTitle : ""}
        >
            <div className="personal-campaigns__list">
                <Swiper
                    spaceBetween={16}
                    slidesOffsetAfter={16}
                    slidesOffsetBefore={16}
                    slidesPerView={1.2}
                >
                    {filteredCampaigns.map((campaign: SapCrmCampaign) => (
                        <SwiperSlide
                            key={`slide_${campaign.kampanjeId}`}
                            aria-labelledby={campaign.langBeskrivelse}
                        >
                            <button onClick={() => handleClick(campaign)}>
                                <PersonalCampaign campaign={campaign} />
                            </button>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </Container>
    )
}
export default PersonalCampaigns
