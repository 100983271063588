import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "@app/types"

import { CartPickerLazy } from "@ng-mw/shared-react-components/cart"

import { TrumfIdLoginDrawerWrapper } from "@ng-mw/shared-react-components/trumf-id-login-drawer"
import AgreementDeactivationPopup from "@modules/user/pages/AgreementDeactivationPopup"
import AppOnboardingPoster from "@modules/onboarding/pages/AppOnboardingPoster"
import AppRatingPopup from "@modules/onboarding/pages/AppRatingPopup"
import AppUpgradePopup from "@modules/onboarding/pages/AppUpgradePopup"
import BarcodePopup from "@modules/barcode/pages/BarcodePopup"
import CategoryFilterPopup from "@modules/products/pages/CategoryFilterPopup"
import CheckoutPaymentPopup from "@modules/checkout/pages/CheckoutPaymentPopup"
import ClimateComparisonPopup from "@modules/insight/climate/pages/ClimateComparisonPopup"
import ClimateInformationPopup from "@modules/insight/climate/pages/ClimateInformationPopup"
import ClimateNumbersPopup from "@modules/insight/climate/pages/ClimateNumbersPopup"
import ClimateProductGroupsInfoPopup from "@modules/insight/climate/pages/ClimateProductGroupsInfoPopup"
import CouponInfoPopup from "@modules/savings/pages/CouponInfoPopup"
import DietEnergyPopup from "@modules/insight/diet/pages/DietEnergyPopup"
import DietPropertyPopup from "@modules/insight/diet/pages/DietPropertyPopup"
import FeedbackPopup from "@modules/shared/pages/FeedbackPopup"
import HandoverPickerPopup from "@modules/checkout/pages/HandoverPickerPopup"
import InsightAboutPopup from "@modules/insight/common/pages/InsightAboutPopup"
import InsightGroupPopup from "@modules/insight/common/pages/InsightGroupPopup"
import LoyaltyClubInfoPopup from "@modules/loyalty-club/pages/LoyaltyClubInfoPopup"
import InsightKeyFiguresPopup from "@modules/insight/diet/pages/InsightKeyFiguresPopup"
import LoginPopup from "@modules/auth/pages/LoginPopup"
import LogOutPopup from "@modules/auth/pages/LogOutPopup"
import PurchaseComplaintPopup from "@modules/orders/pages/PurchaseComplaintPopup"
import PurchaseDetailsPopup from "@modules/orders/pages/PurchaseDetailsPopup"
import PushConsentsPopup from "@modules/user/pages/PushConsentsPopup"
import ProductPopup from "@modules/products/pages/ProductPopup"
import RegistrationPopup from "@modules/onboarding/pages/RegistrationPopup"
import RecipeRatingPopup from "@modules/recipes/pages/RecipeRatingPopup"
import ShoppingListChangeNamePopup from "@modules/shopping-lists/pages/ShoppingListChangeNamePopup"
import ShoppingListCreatePopup from "@modules/shopping-lists/pages/ShoppingListCreatePopup"
import ShoppingListRemovePopup from "@modules/shopping-lists/pages/ShoppingListRemovePopup"
import UserQrPopup from "@modules/user/pages/UserQrPopup"
import RecipePopup from "@modules/recipes/pages/RecipePopup"
import ForcedUpgradePopup from "@modules/onboarding/pages/ForcedUpgradePopup"
import OsUpgradePopup from "@modules/onboarding/pages/OsUpgradePopup"
import FirstTrumfIdModal from "@modules/onboarding/pages/FirstTrumfIdModal"
import NewsPopup from "@modules/onboarding/pages/NewsPopup"
import ActivateLoyaltyClubPopup from "@modules/loyalty-club/pages/ActivateLoyaltyClubPopup"
import WelcomePopup from "@modules/loyalty-club/pages/WelcomePopup"
import GamePage from "@modules/seasonal/pages/GamePage"
import PersonalCampaignsPopup from "@modules/user/pages/PersonalCampaignsPopup"
import PersonalCampaignPopup from "@modules/user/pages/PersonalCampaignPopup"
import TmpCampaignPopup from "@modules/home/pages/TmpCampaignPopup"
import TopBannerPopup from "@modules/home/TopBannerPopup"

const Popups = () => {
    const safeArea = useSelector((state: RootState) => state.native.safeArea)

    return (
        <>
            <AppRatingPopup />
            <BarcodePopup />
            <FirstTrumfIdModal />

            <PurchaseDetailsPopup />
            <PurchaseComplaintPopup />

            <CouponInfoPopup />
            <CategoryFilterPopup />

            <InsightAboutPopup />
            <InsightKeyFiguresPopup />
            <InsightGroupPopup />
            <DietEnergyPopup />
            <DietPropertyPopup />
            <GamePage />
            <ClimateComparisonPopup />
            <ClimateInformationPopup />
            <ClimateNumbersPopup />
            <ClimateProductGroupsInfoPopup />

            <AgreementDeactivationPopup />
            <LoginPopup />
            <PersonalCampaignsPopup />
            <PersonalCampaignPopup />
            <LoyaltyClubInfoPopup />
            <TopBannerPopup />

            {
                // The following popups needs to be later and in a specific order:
            }

            <FeedbackPopup />
            <CheckoutPaymentPopup />
            <ProductPopup /> {/* Product should be fairly high */}
            <HandoverPickerPopup />

            <CartPickerLazy /> {/* Merge or choose cart when logging in */}

            <RegistrationPopup />
            <AppUpgradePopup />
            <NewsPopup />
            <TmpCampaignPopup />
            <ShoppingListRemovePopup />
            <ShoppingListChangeNamePopup />
            <ShoppingListCreatePopup />
            <RecipePopup />
            <RecipeRatingPopup />
            <ActivateLoyaltyClubPopup />
            <ForcedUpgradePopup />
            <OsUpgradePopup />
            <LogOutPopup />

            {
                // The following popups needs to be later and in a specific order:
            }
            <WelcomePopup />
            <PushConsentsPopup />
            <AppOnboardingPoster />
            <UserQrPopup />
            <TrumfIdLoginDrawerWrapper safeAreaBottom={safeArea.bottom} />
        </>
    )
}

export default Popups
