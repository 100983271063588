import { SafeArea } from "@app/types/SafeArea"

export enum NativeAction {
    SetSafeArea = "native:set-safe-zone",
    SetNetworkStatus = "native:set-network-status",
}
interface SetNetworkStatusReturn {
    type: NativeAction.SetNetworkStatus
    payload: boolean
}
export const setNetworkStatus = (status: boolean): SetNetworkStatusReturn => {
    return {
        type: NativeAction.SetNetworkStatus,
        payload: status,
    }
}

interface SetSafeAreaReturn {
    type: NativeAction.SetSafeArea
    payload: SafeArea
}
export const setSafeArea = (safeArea: SafeArea): SetSafeAreaReturn => {
    return {
        type: NativeAction.SetSafeArea,
        payload: safeArea,
    }
}

export type AnyNativeAction = SetNetworkStatusReturn | SetSafeAreaReturn
