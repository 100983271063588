import { Native } from "@app/types/root-state/native"
import { AnyNativeAction, NativeAction } from "./nativeCreator"

const init: Native = {
    safeArea: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    online: true,
}

export default (state = init, action: AnyNativeAction): Native => {
    switch (action.type) {
        case NativeAction.SetSafeArea:
            return {
                ...state,
                safeArea: {
                    ...state.safeArea,
                    ...action.payload,
                },
            }
        case NativeAction.SetNetworkStatus:
            return { ...state, online: action.payload }
        default:
            return state
    }
}
